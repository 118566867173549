<template>
  <div>
    <app-dialog
      v-model="open"
      :title="`creditors.terminate.trigger${
        details.isTerminated ? 'Reactivate' : 'Terminate'
      }Creditor`"
      primary-action-text="navigation.yesSend"
      secondary-action-text="navigation.noCancel"
      :loading="loading"
      @submit="onSubmit"
      @close="close"
    >
      <app-alert v-if="error" :value="!!error" class="mb-4">
        {{ $t('errorOccured') }}:
        <p class="mt-4">{{ error.statusText }}</p>
        <p class="mt-4">{{ error.content }}</p>
      </app-alert>
      <p>
        {{
          $t(
            `creditors.terminate.shouldCreditorBe${
              details.isTerminated ? 'Reactivated' : 'Terminated'
            }`,
            {
              name: details.creditorName
            }
          )
        }}
      </p>
      <div class="my-4 subtitle-1 grey--text">
        {{ $t(`creditors.terminate.hint${details.isTerminated ? 'Reactivate' : 'Terminate'}`) }}
      </div>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.ok"
      :title="`creditors.terminate.${
        details.isTerminated ? 'creditorReactivated' : 'creditorTerminated'
      }`"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/Admin/store';
import CreditorDialogMixin from '@/mixins/CreditorDialogMixin';

export default {
  name: 'TerminateCreditorDialog',

  mixins: [CreditorDialogMixin],

  methods: {
    ...mapActions(NAMESPACE, ['terminateCreditor', 'reactivateCreditor']),

    async onSubmit() {
      this.loading = true;

      let error;

      if (this.details.isTerminated) {
        const result = await this.reactivateCreditor(this.creditorId);
        error = result.error;
      } else {
        const result = await this.terminateCreditor(this.creditorId);
        error = result.error;
      }

      this.loading = false;

      if (error) {
        this.error = error.response?.data;
        return;
      }

      this.submitted = true;
    }
  }
};
</script>
