<template>
  <app-data-table
    :headers="filterItemsWithFulfilledCondition(tableHeaders)"
    :items="formattedTableData"
    :loading="loadingList"
    :total-data-length="count"
  >
    <template #title>
      <div class="table-title__content">
        <span class="text-h5">{{ $tc('creditors.creditorsCounted', count) }}</span>
      </div>
    </template>

    <template #item="{ item }">
      <tr :class="{ 'v-data-table-row--error': item.userActive === false }">
        <app-table-data :sorted="isSorted(0)" :header="tableHeaders[0]">
          <app-status-chip :value="item.status" />
        </app-table-data>
        <app-table-data :sorted="isSorted(1)" :header="tableHeaders[1]">
          <div class="semi-bold">{{ item.creditorName }}</div>
          <div class="subtitle-1 grey--text">
            {{ item.city }}
          </div>
        </app-table-data>
        <app-table-data :sorted="isSorted(2)" :header="tableHeaders[2]">
          {{ item.userName }}
        </app-table-data>
        <app-table-data :sorted="isSorted(3)" :header="tableHeaders[3]">
          {{ item.sapNumber || '-' }}
          <div class="subtitle-1 grey--text">
            {{ item.isSentToSap ? $t('users.sent') : $t('users.notSent') }}
          </div>
        </app-table-data>
        <app-table-data :sorted="isSorted(4)" :header="tableHeaders[4]">
          {{ item.setUpFeeCharged ? $t('yes') : $t('no') }}
        </app-table-data>
        <app-table-data :sorted="isSorted(5)" :header="tableHeaders[5]">
          {{ item.email }}
          <div v-if="item.userEmail && item.userEmail !== item.email" class="subtitle-1 grey--text">
            {{ item.userEmail }}
          </div>
        </app-table-data>
        <app-table-data :sorted="isSorted(6)" :header="tableHeaders[6]">
          {{ item.created | formatDate }}
          <div class="subtitle-1 grey--text">
            {{ item.updated | formatDate }}
          </div>
        </app-table-data>
        <app-table-data v-if="!isProductAdmin" :sorted="isSorted(7)" :header="tableHeaders[7]">
          {{ item.productTrans || '-' }}
        </app-table-data>
        <td>
          <v-menu content-class="app-menu" offset-y left>
            <template #activator="{ on }">
              <app-btn min-height="unset" class="py-1 px-2" outlined v-on="on">
                {{ $t('actions') }}
                <v-icon small class="ml-1">mdi-chevron-down</v-icon>
              </app-btn>
            </template>

            <v-card>
              <v-list>
                <template v-for="(action, index) in actions">
                  <v-list-item
                    v-if="conditionIsFulfilled(item, action)"
                    :key="index"
                    dense
                    class="subtitle-1"
                    :disabled="!isPermitted(item, action)"
                    @click="action.callback(item)"
                  >
                    <v-list-item-content>
                      {{ $t(action.title(item)) }}
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-card>
          </v-menu>
        </td>
      </tr>
    </template>

    <template #no-data>
      <p>{{ $t('creditors.noData') }}</p>
    </template>
  </app-data-table>
</template>

<script>
import formatProduct from '@/helper/filter/formatProduct';
import { NAMESPACE } from '@/modules/Admin/store';
import { mapState } from 'vuex';
import TableMixin from '@/mixins/TableMixin';

export default {
  name: 'CreditorsTable',

  mixins: [TableMixin],

  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    count: {
      type: Number,
      default: 0
    },
    creditorActions: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapState(NAMESPACE, ['creditors', 'loadingList']),

    formattedTableData() {
      return JSON.parse(JSON.stringify(this.tableData)).map((entry) => ({
        ...entry,
        productTrans: formatProduct(entry.product)
      }));
    },

    actions() {
      return [
        this.queryParam.EDIT_CREDITOR,
        this.queryParam.SAP_REGISTRATION,
        this.queryParam.SEND_REGISTRATION_CASE_FILE,
        this.queryParam.SET_UP_FEE,
        this.queryParam.CREATE_CREDITOR_USER,
        this.queryParam.UPDATE_CREDITOR_USER,
        this.queryParam.DISABLE_CREDITOR_USER,
        this.queryParam.RESET_PASSWORD,
        this.queryParam.MANAGE_CONDITIONS,
        this.queryParam.REQUEST_SEPA_UPDATE,
        this.queryParam.SEND_REGISTRATION_MAIL,
        this.queryParam.SET_CREDITOR_PERMISSIONS,
        this.queryParam.SET_DRIVER_PERMISSIONS,
        this.queryParam.BLOCK_CREDITOR,
        this.queryParam.TERMINATE_CREDITOR
      ].map((param) => this.creditorActions.find((action) => action.param === param));
    },

    tableHeaders() {
      return [
        { text: this.$t('status'), value: 'status', sortable: false },
        {
          text: this.$t('companyOrName'),
          subText: this.$t('city'),
          value: 'name',
          alignLeft: true
        },
        { text: this.$t('users.userName'), value: 'username' },
        { text: this.$t('sapNumber'), subText: this.$t('users.sentToSap'), value: 'sapNumber' },
        {
          text: this.$t('creditors.setUpFee'),
          value: 'setUpFeeCharged',
          sortable: false
        },
        {
          text: this.$t('email'),
          subText: this.$t('creditors.deviatingUserEmail'),
          value: 'email'
        },
        { text: this.$t('createdOn'), subText: this.$t('creditors.lastUpdated'), value: 'created' },
        {
          text: this.$t('product'),
          value: 'product',
          condition: !this.isProductAdmin
        },
        { text: this.$t('users.actions'), value: null, sortable: false }
      ];
    }
  },

  methods: {
    conditionIsFulfilled(creditor, item) {
      return item.condition === undefined || item.condition(creditor) === true;
    },

    isPermitted(creditor, item) {
      return item.permission === undefined || item.permission(creditor) === true;
    }
  }
};
</script>

<style scoped>
.theme--light.v-data-table.app-data-table tr td {
  vertical-align: middle;
}
</style>
