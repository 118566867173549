<template>
  <ValidationObserver ref="form" v-slot="{ failed }" tag="form" @submit.prevent="onSubmit">
    <app-dialog
      v-model="open"
      title="users.updateUser"
      primary-action-text="navigation.send"
      secondary-action-text="navigation.cancel"
      :loading="loading"
      :disabled="failed"
      @submit="onSubmit"
      @close="close"
    >
      <v-row>
        <v-col cols="12">
          <app-text-field v-model="formData.name" label="name" required />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <app-text-field v-model="formData.email" label="email" rules="email|required" />
        </v-col>
      </v-row>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.ok"
      title="users.updatedUser"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    />
  </ValidationObserver>
</template>

<script>
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/Admin/store';
import showSnackbar from '@/helper/showSnackbar';
import CreditorDialogMixin from '@/mixins/CreditorDialogMixin';

export default {
  name: 'UpdateCreditorUserDialog',

  mixins: [CreditorDialogMixin],

  data: () => ({
    formData: {
      name: '',
      email: ''
    },
    emailAlreadyAssigned: false
  }),

  methods: {
    ...mapActions(NAMESPACE, ['updateUser']),

    async onSubmit() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      this.loading = true;

      const { error } = await this.updateUser({
        userIdentifier: this.creditorId,
        name: this.formData.name,
        email: this.formData.email
      });

      this.loading = false;
      if (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return;
      }

      this.submitted = true;
      setTimeout(() => this.resetForm(), 200);
    },

    resetForm() {
      this.formData = {
        name: '',
        email: '',
        password: '',
        sendMail: false
      };
    }
  },

  watch: {
    details: {
      immediate: true,
      handler(details) {
        if (!details.email) return;

        this.formData.email = details.userEmail;
        this.formData.name = details.userName;
      }
    }
  }
};
</script>
